import React from 'react';
import { UiKit } from '@datavant/uikit';

export const LegalDisclaimer = (
    <UiKit.Chakra.VStack spacing={UiKit.consistency.buffer} align={'flex-start'}>
        <UiKit.Text.BodyRegular>
            Upon submission, your request will be processed by our team. Check for status on the Request
            screen or the Request Detail screen.
        </UiKit.Text.BodyRegular>
        <UiKit.Text.BodyRegularBold>
            Thank you for using Smart Request. SmartRequest is an enhanced method for receiving requests for
            medical records that medical providers must approve and Ciox Health is not legally obligated to
            respond to requests made through SmartRequest. If after review your request does not meet the
            requirements of the request type you selected in Smart Request, Ciox Health may unilaterally
            reject the request and it must be submitted directly to the medical provider from whom you seek
            medical records. Ciox Health and the medical provider will not be responsible for any fines, late
            fees, penalties, assessments, or other monetary damages for failing to timely respond to requests
            improperly submitted through SmartRequest.
        </UiKit.Text.BodyRegularBold>
    </UiKit.Chakra.VStack>
);
export const DisclosureNotice = (
    <UiKit.Chakra.VStack alignItems={'flex-start'} spacing={UiKit.consistency.buffer}>
        <UiKit.Text.BodyRegular>
            This information has been disclosed to you from records that may be protected by federal
            confidentiality rules (42 CFR part 2). The federal rules prohibit you from making any further
            disclosure of information in this record that identifies a patient as having or having had a
            substance use disorder either directly, by reference to publicly available information, or through
            verification of such identification by another person unless further disclosure is expressly
            permitted by written consent of the individual whose information is being disclosed or as
            otherwise permitted by 42 CFR part 2. A general authorization for the release of medical or other
            information is not sufficient for this purpose (see 42 CFR § 2.31). The federal rules restrict any
            use of the information to investigate or prosecute with regard to a crime any patient with a
            substance use disorder, except as provided at 42 CFR § § 2.12(c)(5) and 2.65.
        </UiKit.Text.BodyRegular>
        <UiKit.Text.BodyRegular>
            If the enclosed record pertains to HIV/AIDs, it has been disclosed to you from records whose
            confidentiality is protected by federal and perhaps, state law, which prohibits you from making
            any further disclosure of such information without the specific consent of the person to whom such
            information pertains or as otherwise permitted by state law. A general authorization for this
            release of health or other information is not sufficient for this purpose.
        </UiKit.Text.BodyRegular>
        <UiKit.Text.BodyRegular>
            If the information requested is from a facility located within the Washington State area then this
            information will fall under the RCW 70.02.300 which states that this information has been
            disclosed to you from records whose confidentiality may be protected by state law. State law
            prohibits you from making any further disclosure of it without the specific written authorization
            of the person to whom it pertains, or as otherwise permitted by state law. A general authorization
            for the release of this protected information is not sufficient for this purpose.
        </UiKit.Text.BodyRegular>
    </UiKit.Chakra.VStack>
);

export const PrivacyPolicy = (
    <UiKit.Chakra.VStack alignItems={'flex-start'} spacing={UiKit.consistency.buffer}>
        <UiKit.Text.BodyRegular>
            <i>Last Updated: February 10, 2021</i>
        </UiKit.Text.BodyRegular>
        <UiKit.Text.BodyRegular>
            Ciox's Privacy Policy describes how Ciox Health (“Ciox,” “we,” and “us”) collects, uses, and
            discloses the Personal Information we collect from you when you use our website or otherwise
            interact with us (the “Services”). Please carefully review this Privacy Policy prior to using our
            Services or sharing your Personal Information with us.
        </UiKit.Text.BodyRegular>
        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyLarge>
                Health Insurance Portability and Accountability Act of 1996 (HIPAA) & State Law
            </UiKit.Text.BodyLarge>
            <UiKit.Text.BodyRegular>
                Our use and disclosure of certain of your information may be subject to the requirements of
                the Health Insurance Portability and Accountability Act of 1996 and its implementing
                regulations (“HIPAA”) and applicable state law. Any information that you submit to us that
                constitutes “Protected Health Information,” as defined by HIPAA, is subject to HIPAA and
                applicable state law, and such laws control to the extent of any conflict with this Privacy
                Policy. The term “Protected Health Information” or “PHI” refers to individually identifiable
                health information about your past, present or future physical or mental health or condition,
                the provision of health care to you or the past, present or future payment for such care.
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>

        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyLarge>Ciox as a Service Provider</UiKit.Text.BodyLarge>
            <UiKit.Text.BodyRegular>
                We are primarily a service provider for other businesses. In the course of providing services
                for other businesses, we may collect your Personal Information from our business customers.
                Generally, the businesses that we serve are responsible for determining how we may use and
                share your Personal Information. If you have questions about how your Personal Information is
                collected and used, we may direct you to the business who is responsible for your Personal
                Information. To the extent of any conflict between this Privacy Policy and our agreements
                (including HIPAA business associate agreements) with a business customer, the agreement will
                generally control.
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>

        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyLarge>Personal Information</UiKit.Text.BodyLarge>
            <UiKit.Text.BodyRegularBold>Personal Information We Collect</UiKit.Text.BodyRegularBold>
            <UiKit.Text.BodyRegular>
                “Personal Information” is information that identifies, relates to, describes, is capable of
                being associated with, or could reasonably be linked, directly or indirectly, with you or your
                household. Ciox collects Personal Information from you when send us emails or otherwise
                voluntarily submit your information to us.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                Depending on how you use the Services, we may collect the following categories of Personal
                Information about you:
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                <UiKit.Chakra.UnorderedList>
                    <UiKit.Chakra.ListItem>
                        Direct Identifiers, such as your name, mailing address, email address, phone number,
                        and account numbers. Typically, we collect this information directly from you to
                        contact you regarding administrative notices, your use of the Services, or in
                        connection with your interactions with us, such as through an employment application.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Internet Activity Information, such as your browsing history and browser preferences.
                        Typically, we collect this information through cookies and other data collection
                        technologies to understand how you use our website.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Commercial Information, such as your financial and payment information, including
                        credit card and payment card information. Typically, we collect this information
                        directly from you to process payments you request or otherwise adjust your account.
                    </UiKit.Chakra.ListItem>
                </UiKit.Chakra.UnorderedList>
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                In addition the purpose of collection described above, we may also collect Personal
                Information generally for the following reasons:
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                <UiKit.Chakra.UnorderedList>
                    <UiKit.Chakra.ListItem>For the purpose for which you provided it.</UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>To maintain and service your account.</UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>To administer and improve our website.</UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        To communicate with you and respond to inquiries you send to us.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        To promote our products and services to you, if given your permission.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        To comply with legal, regulatory and risk management obligations.
                    </UiKit.Chakra.ListItem>
                </UiKit.Chakra.UnorderedList>
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                In addition, we may use your Personal Information in the aggregate in a non-identifiable way
                in order to better understand the services being provided, how to improve these services and
                how to improve the Services. One way in which we collect your non-identifiable information is
                through anonymous surveys. Participation in such surveys is voluntary and is not connected to
                any of your Personal Information. We may provide aggregated information to third parties, but
                when we do so we do not provide any of your Personal Information without your express
                permission.
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>

        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyLarge>How We Disclose the Information We Collect</UiKit.Text.BodyLarge>
            <UiKit.Text.BodyRegular>
                We share your Personal Information for the following reasons:
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                Service Providers. We may share your Personal Information with third parties that provide
                services to us. We may use third party service providers to host the Services and gather and
                use on our behalf your Personal Information as contemplated by this Privacy Policy and
                applicable law. All such third parties are subject to confidentiality obligations in an
                attempt to protect your information as much as is commercially reasonable.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                In Connection with a Legal Right or Obligation. We may investigate and disclose information
                from or about you if we have a good faith belief that such investigation or disclosure is (a)
                reasonably necessary to comply with legal process and law enforcement instructions and orders,
                such as a search warrant, subpoena, statute, judicial proceeding, or other legal process
                served on us; (b) helpful to prevent, investigate, or identify possible wrongdoing in
                connection with the Services; or (c) protect our rights, reputation, property, or that of our
                users, affiliates, or the public.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                In a Transaction. If we, or any of our businesses, are sold or disposed of as a going concern,
                whether by merger, reorganization, sale of assets or otherwise, or in the event of an
                insolvency, bankruptcy or receivership, any and all Personal Information, including your
                account information, may be one of the assets sold or merged in connection with that
                transaction. Information about you may also need to be disclosed in connection with a
                commercial transaction where we or any one of our businesses are seeking financing,
                investment, support or funding. In such transactions, Personal Information will be subject to
                the promises made in any pre-existing Privacy Policy in effect when the information was
                obtained.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                Except as stated in this Privacy Policy, we do not sell, distribute, or release to a third
                party your Personal Information without notice to you.
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>

        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyLarge>Cookies and Data Collection Technologies</UiKit.Text.BodyLarge>
            <UiKit.Text.BodyRegular>
                Our online Services use cookies (small text files stored either temporarily or permanently on
                a user's computer hard disk, which allow the website to recognize the user and track usage of
                the site, preferences, IP addresses, and pages visited, and to gather data and marketing
                information). Cookies may improve and/or simplify the use of Ciox online services.
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>

        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyLarge>How We Protect Your Information</UiKit.Text.BodyLarge>
            <UiKit.Text.BodyRegular>
                Communications between your browser and portions of the online Services containing Personal
                Information are protected with Secure Socket Layer (“SSL”) encryption. This encryption is to
                help protect your information while it is being transmitted. Once we receive your information
                we strive to maintain the physical and electronic security of your Personal Information using
                commercially reasonable efforts.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                NO DATA TRANSMISSION OVER THE INTERNET OR ANY WIRELESS NETWORK CAN BE GUARANTEED TO BE
                PERFECTLY SECURED. AS A RESULT, WHILE WE STRIVE TO PROTECT YOUR PERSONAL INFORMATION USING
                COMMERCIALLY AVAILABLE AND INDUSTRY STANDARD ENCRYPTION TECHNOLOGY, WE CANNOT ENSURE OR
                GUARANTEE THE SECURITY OF ANY INFORMATION YOU TRANSMIT TO US, AND YOU DO SO AT YOUR OWN RISK.
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>

        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyLarge>
                In the Event of a Security Breach of Your Personal Information
            </UiKit.Text.BodyLarge>
            <UiKit.Text.BodyRegular>
                If we determine that your Personal Information has or may reasonably have been disclosed due
                to a security breach of our systems, we will notify you in accordance with and to the extent
                required by applicable state and federal law using the information that we have on file.
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>

        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyLarge>Disclosures for California Residents</UiKit.Text.BodyLarge>
            <UiKit.Text.BodyRegular>
                If you are a resident of California, you may be entitled to the following privacy rights:
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                The right to know. You have the right to request to know (i) the specific pieces of Personal
                Information we have about you; (ii) the categories of Personal Information we have collected
                about you in the last 12 months; (iii) the categories of sources from which that Personal
                Information was collected; (iv) the categories of your Personal Information that we sold or
                disclosed in the last 12 months; (v) the categories of third parties to whom your Personal
                Information was sold or disclosed in the last 12 months; and (vi) the purpose for collecting
                and selling your Personal Information. Generally:
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                <UiKit.Chakra.UnorderedList>
                    <UiKit.Chakra.ListItem>
                        Within the preceding 12 months, Ciox has collected the categories of Personal
                        Information detailed in the “Personal Information We Collect” section above.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Ciox does not and will not sell your Personal Information.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Ciox has not disclosed your Personal Information to third parties within the preceding
                        12 months.
                    </UiKit.Chakra.ListItem>
                </UiKit.Chakra.UnorderedList>
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                The right to deletion. You have the right to request that we delete the Personal Information
                that we have collected or maintain about you. We may deny your request under certain
                circumstances, such as if we need to comply with our legal obligations or complete a
                transaction for which your Personal Information was collected. If we deny your request for
                deletion, we will let you know the reason why.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                "The right to opt out of the sale of your Personal Information. You have the right to opt out
                of the sale of your Personal Information. Ciox does not and will not sell your Personal
                Information. If we change our business practices, we will update this Privacy Policy, notify
                you, and honor your right to opt out of the sale of your Personal Information.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                The right to equal service. If you choose to exercise any of these rights, we will not
                discriminate against you in any way. If you exercise certain rights, understand that you may
                be unable to use or access certain features of our services."
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                The CCPA does not apply to health information that (i) is deidentified in accordance with the
                requirements of deidentification set forth in Section 164.514 of Part 164 of Title 45 of the
                Code of Federal Regulations, and (ii) is derived from patient information that was originally
                collected, created, transmitted, or maintained by an entity regulated by the Health Insurance
                Portability and Accountability Act, the Confidentiality of Medical Information Act, or the
                Federal Policy for the Protection of Human Subjects, also know as the Common Rule. Ciox may
                use or disclose deidentified information so long as the entities to who Ciox discloses
                deidentified data are prohibited from re-identifying or attempting to re-identify data.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                "You may exercise your right to know and your right to deletion twice a year free of charge.
                To exercise your right to know or your right to deletion, contact us via our C3 at
                844-882-3809 or visit our C3 website at www.cioxcomplianceconnection.com."
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                "Ciox will take steps to verify your identity before processing your request to know or
                request to delete. We will not fulfill your request unless you have provided sufficient
                information for us to reasonably verify you are the individual about whom we collected
                Personal Information. If you have an account with us, we will use our existing account
                authentication practices to verify your identity. If you do not have an account with us, we
                may request additional information about you to verify your identity. We will only use the
                Personal Information provided in the verification process to verify your identity or authority
                to make a request and to track and document request responses, unless you initially provided
                the information for another purpose.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                "You may use an authorized agent to submit a request to know or a request to delete. When we
                verify your agent's request, we may verify both your and your agent's identity and request a
                signed document from you that authorizes your agent to make the request on your behalf. To
                protect your Personal Information, we reserve the right to deny a request from an agent that
                does not submit proof that they have been authorized by you to act on their behalf."
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                "Shine the Light - Third Party Marketing: This Privacy Policy describes how we may share your
                Personal Information, including for marketing purposes. California residents are entitled to
                request and obtain from Ciox once per calendar year information about any of your Personal
                Information shared with third parties for their own direct marketing purposes, including the
                categories of information and the names and addresses of those businesses with which we have
                shared such information. To request this information and for any other questions about our
                privacy practices and compliance with California law, please contact us at our C3 at
                844-882-3809 or visit our C3 website at www.cioxcomplianceconnection.com.
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>

        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyLarge>Third Party Privacy</UiKit.Text.BodyLarge>
            <UiKit.Text.BodyRegular>
                This Privacy Policy applies only to the Services provided by Ciox Health. The Services may
                contain links to other websites, which may be subject to a different privacy policy or are
                otherwise maintained or provided by a third party. We are not responsible for the privacy
                practices of any third party website you access from our Services. You should review the
                privacy policy of every website before using the website or submitting any information to the
                website.
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>

        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyLarge>Changes to Our Policy</UiKit.Text.BodyLarge>
            <UiKit.Text.BodyRegular>
                We reserve the right to modify or amend this Privacy Policy at any time. All changes to this
                Privacy Policy will be effective immediately upon their posting to the Services. We will
                notify you of material changes to this Privacy Policy by conspicuously posting the changes on
                the Services. Information collected before changes are made will be treated in accordance with
                the previous Privacy Policy. Continued use of the Services after the effective date of a
                modified privacy policy will indicate your agreement to any modified terms. Each version of
                our Privacy Policy will be prominently marked with an effective date.
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>

        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyLarge>Contact Information</UiKit.Text.BodyLarge>
            <UiKit.Text.BodyRegular>
                You may submit any questions or concerns about this Privacy Policy or our privacy practices by
                contacting us through the following methods:
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                <UiKit.Chakra.UnorderedList styleType={'none'}>
                    <UiKit.Chakra.ListItem>
                        Elizabeth Delahoussaye, Chief Privacy Officer
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Robbie Hudec, Chief Information Security Officer
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>Ciox Health</UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>925 North Point Parkway</UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>Suite 350</UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>Alpharetta, GA 30005</UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>844-882-3809</UiKit.Chakra.ListItem>
                </UiKit.Chakra.UnorderedList>
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                or visit our C3 website at www.cioxcomplianceconnection.com.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                We regularly review our compliance with this Privacy Policy. If you believe your privacy
                rights have been violated, you have the right to file a complaint. You may do so by contacting
                the Ciox Compliance Connection at 844-882-3809.
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>
    </UiKit.Chakra.VStack>
);

export const TermsAndConditions = (
    <UiKit.Chakra.VStack alignItems={'flex-start'} spacing={UiKit.consistency.buffer}>
        <UiKit.Chakra.VStack alignItems={'flex-start'}>
            <UiKit.Text.BodyRegular>
                NOTICE: THIS IS A LEGALLY BINDING CONTRACT. PLEASE READ IT CAREFULLY. BY UPLOADING DOCUMENTS
                AND DATA TO AND ACCEPTING THE DELIVERY OF MEDICAL RECORDS THROUGH CIOX HEALTH, LLC'S INTERNET
                PORTAL LOCATED AT www.smartrequest.com (the “Site”) YOU ACCEPT THIS AGREEMENT AND AGREE TO BE
                BOUND BY THE TERMS AND CONDITIONS.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                CIOX Health, LLC, located at 925 North Point Parkway, Suite 350, Alpharetta, Georgia 30005.
                All rights reserved. By using CIOX Health, LLC (“CIOX”) software and services, you the
                individual end-user (hereafter referred to as “USER”), agree to the terms and conditions as
                outlined in this agreement (collectively referred to hereafter as the “Agreement”). By
                clicking on 'Accept' below USER accepts and agrees to be legally bound by the terms and
                conditions of this Agreement. CIOX reserves the right to modify this Agreement and its website
                (www.smartrequest.com) at any time. USER agrees that continued access or use of the website
                shall be deemed USER'S conclusive acceptance of the modifications.
            </UiKit.Text.BodyRegular>
            <UiKit.Text.BodyRegular>
                <UiKit.Chakra.OrderedList spacing={UiKit.consistency.smallBuffer}>
                    <UiKit.Chakra.ListItem>
                        Authorization. USER affirms that it is authorized by law or by the subject patient to
                        obtain, review and keep a copy of the subject patient's medical and other related
                        records. USER hereby authorizes CIOX to retrieve, obtain, store, and transmit uploaded
                        documents by USER as well as medical and other related records obtained from
                        healthcare and other service providers. USER unconditionally represents and warrants
                        to CIOX that USER: (i) has requisite authority to enter into this Agreement, upload
                        relevant documents to the CIOX portal and obtain any and all medical records that USER
                        requests; and (ii) is not seeking any records for any illegal or unethical purpose.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        USER'S CIOX Account. USER is required to create and maintain an account with CIOX to
                        utilize CIOX software and services located at www.smartrequest.com (as further defined
                        in the “Authorization” paragraph). “Software” is defined as the CIOX online web
                        interface and FTP server, including all components thereof and all updates, patches,
                        fixes, modifications, and enhancements thereto, including releases of new versions,
                        whether provided to USER via download, automatically without additional consent or
                        action on USER's part or otherwise, and any and all accompanying documentation, files
                        and materials. The Software and its components are licensed, owned and/or operated by
                        CIOX, and offered to USER subject to USER's acceptance, without modification, of all
                        the terms and conditions contained herein and all other operating rules, policies and
                        procedures that may be published from time to time by CIOX on its website or to USER's
                        user account. In creating such an account USER is bound to the terms and conditions of
                        this Agreement, the USER agrees that each such USER account may only be used by the
                        person/entity who created and maintains the account and, to the extent the account is
                        for an entity, organization, or law firm, any members, partners, owners, employees, or
                        agents of that entity, organization, or firm. USER's user account will be available to
                        USER online through most major web browsers. USER understands and agrees that USER is
                        responsible for providing any necessary equipment or services to access the Software,
                        including internet connection services and computer equipment and programs (including
                        web browsers, etc.) capable of accessing the user account online. USER understands and
                        agrees that USER has no right to access the user account or any information or
                        documents on that user account except as set forth herein, and that CIOX reserves the
                        right to freeze or deny access to any user account that it believes in its sole
                        discretion is not maintained or utilized in accordance with this Agreement. USER is
                        responsible for maintaining the security of USER's login information to USER's user
                        account and agrees to not provide this information to any unauthorized users. USER
                        agrees to immediately notify CIOX of any unauthorized access to USER's account, the
                        Software, or any other breaches of security. To the extent that USER cancels its user
                        account with CIOX, all content, information, and documents maintained by CIOX on its
                        servers in conjunction with USER's user account will be deleted by CIOX without notice
                        and at any time after USER's cancellation and subject to CIOX 's sole discretion;
                        accordingly, USER understands and agrees that prior to any such cancellation, USER
                        will take any steps necessary to back up, copy, or otherwise preserve any information,
                        documents or content that has been created, uploaded to, or maintained by CIOX.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Responsibility of USER. USER is responsible for the accuracy and content of all
                        documents uploaded to the CIOX website and all data entered on the website. USER
                        warrants that all documents and information uploaded to the CIOX website are free from
                        viruses, malware, spyware, trojans, worms and other malicious and dangerous software
                        or programs.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Completeness and Accuracy. While CIOX makes every effort to ensure the completeness of
                        records, it does not assume responsibility or any liability whatsoever for the record
                        review. CIOX shall not be responsible for the completeness of information being stored
                        for, and delivered to, USER, and shall not be liable to USER for the unavailability of
                        information requested by USER. The completeness of the records received from the
                        provider is the responsibility of the medical provider itself, not CIOX. CIOX checks
                        the records supplied or made available by the medical provider and reviews the dates
                        of service supplied by USER, and makes no promise, claims or statement that the
                        records reviewed are complete. If USER does not include dates of service in the
                        request, CIOX maintains that the records are complete as provided by the medical
                        provider.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Confidentiality. "Confidential Information" as used herein shall include any and all
                        written and verbal information provided by either party in connection herewith, which
                        is not otherwise available from public sources, including but not limited to patient
                        protected health information as defined in 45 CFR 160.103. Each party acknowledges the
                        representations of the other party that such party's Confidential Information is
                        proprietary and valuable to that party and that any disclosure or unauthorized use
                        thereof may cause harm and loss. In consideration of the disclosure of Confidential
                        Information, each party agrees to receive and to treat all Confidential Information on
                        a strictly confidential and restricted basis and to use all Confidential Information
                        for the sole purpose of performance under this Agreement.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Proprietary Rights of CIOX. CIOX owns all right, title and interest in and to the
                        Software including, without limitation, all intellectual and proprietary rights
                        appurtenant thereto, and, except for the limited license granted to USER herein,
                        nothing in this Agreement shall be construed to transfer, convey, impair, or otherwise
                        adversely affect CIOX's ownership or proprietary rights therein or any other CIOX
                        information or materials, tangible or intangible, in any form and in any medium. CIOX,
                        www.cioxhealth.com, and the CIOX Health, LLC, logo, and all other trademarks, service
                        marks, and graphics used in connection with the Software, are trademarks or registered
                        trademarks of CIOX Health, LLC or CIOX Health, LLC's licensors. Other trademarks,
                        service marks, graphics and logos used in connection with the Software and services
                        may be the trademarks of other third parties. USER's use of the Software and services
                        grants no right or license to reproduce or otherwise use any CIOX Health, LLC or
                        third-party trademarks.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Add-Ons or Third-Party Software Used in Conjunction with the Software. USER
                        understands and agrees that USER's use of any add-ons or third-party software in
                        conjunction with the Software, including but not limited to Google, Microsoft Outlook,
                        Quickbooks, Adobe Acrobat PDF Reader, or any other programs or software, is done
                        entirely at USER's own risk, and that CIOX makes no warranties that such Add-Ons or
                        Third-Party Software will function correctly, not interfere with USER's use of the
                        Software, or not result in damage to your equipment, the Software, the add-ons or
                        third party Software used in conjunction with the Software, or any information or
                        documents contained on such equipment, Software, or Add-Ons or third-party Software.
                        The disclaimer of warranties and limitation on liabilities set forth in this Agreement
                        specifically apply to USER's use of any such Add-Ons or Third-Party Software in
                        conjunction with the Software.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        General Representation and Warranty. USER represents and warrants that (i) USER's use
                        of the Software will be in strict accordance with all applicable laws and regulations
                        (including without limitation any local laws or regulations in USER'S country, state,
                        city, or other governmental area, regarding online conduct and acceptable content, and
                        including all applicable laws regarding the transmission of technical data exported
                        from the United States or the country in which USER resides) and (ii) USER's use of
                        the Software will not infringe or misappropriate the intellectual property rights of
                        CIOX or any third party.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Payment. By signing up for a user account with CIOX for use of the Software and
                        services, USER agrees to pay CIOX any applicable fees such as but not limited to, per
                        page copy fees, duplication fees, reproduction fees, clerical labor, witness fees,
                        research fees, handling fees, base fees, shipping fees, electronic delivery fees,
                        certification fees, rush delivery fees, electronic data storage fees, no records found
                        fee, applicable taxes, and any other charges. The terms of any special billing
                        agreement with CIOX shall supersede any conflicting terms in these Terms and
                        Conditions. Absent any direct conflict the special billing agreement and these Terms
                        and Conditions shall both apply in conjunction. Standard payment terms with Ciox
                        require payment within 30 days unless addressed in a special billing agreement. All
                        records remain the property of CIOX until paid in full. Payment may be satisfied by
                        either bank draft or credit card only at this time. To the extent that any account
                        becomes past due, or the form of payment maintained on behalf of that account is
                        denied when charged for any payment toward the fees that become due, CIOX will notify
                        USER that USER's account is past due and will then attempt, fifteen days later, to
                        contact USER or use the form of payment USER has stored with USER's account. Any
                        amount not paid when due will be charged finance charges equal to one and one-half
                        percent (1.5%) per month or the highest rate permitted by applicable law, whichever is
                        less, determined and compounded daily from the date due until the date paid. CIOX
                        reserves the right to suspend the USER's accounts for failure to make payments as owed
                        and due on their account with CIOX, in which case no access to such an account would
                        be provided to the USER of their users. To the extent that a USER account remains past
                        due for more than sixty (60) consecutive days, the parties hereto understand and agree
                        that CIOX may, in its sole discretion and without notice to the USER who created or
                        maintains the account, cancel the account, and delete any stored information,
                        including documents or other information or content, maintained by CIOX or on CIOX's
                        servers in association with that user account. Any such cancellation and/or deletion
                        of information or content maintained by CIOX will in no manner constitute a waiver of
                        CIOX's right to all past due amounts as well as any and all remaining quarterly
                        payments that USER owes toward satisfaction of payment as set forth in this Agreement.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        DISCLAIMER OF WARRANTIES. THERE ARE NO REPRESENTATIONS OR WARRANTIES THAT APPLY OR
                        THAT ARE MADE TO USER IN ANY WAY IN CONNECTION WITH THE SOFTWARE, SERVICES OR THIS
                        AGREEMENT. TO THE MAXIMUM EXTENT PERMITTED BY LAW, CIOX DISCLAIMS ALL REPRESENTATIONS
                        AND WARRANTIES WITH RESPECT TO THE SOFTWARE, SERVICES AND USER'S ACCESS TO AND USE
                        THEREOF, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
                        WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR ANY WARRANTIES OF
                        TITLE, NON-INFRINGEMENT AND/OR ARISING FROM A COURSE OF DEALING OR USAGE OF TRADE.
                        WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE SOFTWARE AND SERVICES ARE MADE
                        AVAILABLE TO USER ON AN "AS-IS" AND "AS AVAILABLE" BASIS AND CIOX DOES NOT GUARANTEE,
                        WARRANT OR REPRESENT THAT THE SOFTWARE AND SERVICES SHALL MEET USER'S REQUIREMENTS OR
                        THAT USER'S USE, OPERATION OR RESULTS OF USE OF THE SOFTWARE AND SERVICES SHALL BE
                        UNINTERRUPTED, COMPLETE, RELIABLE, ACCURATE, CURRENT, ERROR-FREE, FREE OF COMPUTER
                        VIRUSES OR OTHERWISE SECURE. USER ASSUMES THE ENTIRE RISK OF ACCESSING, UPLOADING TO,
                        DOWNLOADING FROM, OPERATING, AND/OR USING THE SOFTWARE AND SERVICES. TO CLARIFY
                        FURTHER, CIOX AND ITS AFFILIATES MAKE NO WARRANTY THAT (1) THE SOFTWARE AND SERVICES
                        WILL MEET USER'S REQUIREMENTS OR RESULT IN REVENUES OR PROFITS, (2) ANY ADD-ONS OR
                        OTHER SOFTWARE OTHER THAN THE CIOX SOFTWARE WILL FUNCTION WITH THE CIOX SOFTWARE, (3)
                        THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SOFTWARE OR SERVICES WILL BE
                        ACCURATE, RELIABLE, OR DESIRABLE, AND/OR (4) THE QUALITY OF ANY PRODUCTS, SERVICE,
                        INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY USER THROUGH THE SOFTWARE AND
                        SERVICES WILL MEET USER'S EXPECTATIONS. WHILE CIOX WILL USE COMMERCIALLY REASONABLE
                        EFFORTS TO PREVENT UNAUTHORIZED ACCESS TO DATA ENTERED INTO OR MAINTAINED ON THE
                        SOFTWARE, CIOX AND ITS SUPPLIERS MAKE NO WARRANTY THAT SUCH INFORMATION OR DOCUMENTS
                        WILL BE SECURE AGAINST UNAUTHORIZED ACCESS OR OTHER SECURITY BREACHES. CIOX EXPRESSLY
                        DISCLAIMS ANY REPRESENTATIONS OR WARRANTIES THAT USER'S USE OF THE SOFTWARE OR
                        SERVICES WILL SATISFY ANY STATUTORY OR REGULATORY OBLIGATIONS, OR WILL ASSIST WITH,
                        GUARANTEE OR OTHERWISE ENSURE COMPLIANCE WITH ANY APPLICABLE LAWS OR REGULATIONS,
                        INCLUDING BUT NOT LIMITED TO SUCH LAWS AS THE HEALTH INSURANCE PORTABILITY AND
                        ACCOUNTABILITY ACT OF 1996 ("HIPAA"), THE SARBANES-OXLEY ACT OF 2002, OR OTHER FEDERAL
                        OR STATE STATUTES OR REGULATIONS. USER IS SOLELY RESPONSIBLE FOR ENSURING THAT USER'S
                        USE OF THIS SOFTWARE, AS WELL AS RELATED SERVICES OR CONTENT, IS IN ACCORDANCE WITH
                        APPLICABLE LAW, RULES, AND REGULATIONS INCLUDING ANY SUCH RULES OR REGULATIONS SET
                        FORTH IN ANY APPLICABLE STATE'S TRIAL RULES OR RULES OF ATTORNEY CONDUCT/ETHICS. ANY
                        MATERIAL STORED, DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SOFTWARE OR
                        SERVICES IS STORED, DOWNLOADED OR OBTAINED AT USER'S OWN DISCRETION AND RISK, AND USER
                        WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO USER'S COMPUTER SYSTEM OR LOSS OF DATA
                        THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER
                        ORAL OR WRITTEN, OBTAINED BY USER FROM CIOX OR THROUGH, FROM OR RELATING TO THE
                        SOFTWARE OR SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Errors and Corrections. CIOX does not represent or warrant that the CIOX website and
                        Software will be error-free, free of viruses or other harmful components, or that
                        defects will be corrected as soon as practicable. CIOX does not warrant or represent
                        that the information available on or through the CIOX portal will be correct,
                        accurate, timely, or otherwise reliable. CIOX may make improvements and/or changes to
                        its features, functionality, or content at any time.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Amendments to the website and this Agreement. CIOX reserves the right, at its sole
                        discretion, to modify or replace any part of the website or this Agreement. It is
                        USER's responsibility to check the website and this Agreement periodically for
                        changes, which will be accessible through the footer of all pages of Smart Request.
                        USER's continued use of or access to the website and Software following the posting of
                        any changes constitutes acceptance of those changes. CIOX may also, in the future,
                        offer new services and/or features through the CIOX website or in conjunction with the
                        Software (including, the release of new tools and resources). Such new features and/or
                        services shall be subject to this Agreement.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Limitations on Liability. In no event shall CIOX, its officers, directors, employees,
                        partners, parent company(ies), affiliates or suppliers be liable to USER or any third
                        party for any special, incidental, indirect, consequential or punitive damages
                        whatsoever, including those resulting from pass through fees from healthcare providers
                        or their business associates, loss of use, loss of data, lost profits, whether or not
                        foreseeable or if CIOX has been advised of the possibility of such damages, or based
                        on any theory of liability, including breach of contract or warranty, negligence or
                        other tortious action, or any other claim arising out of or in connection with your
                        use of or access to the Software or any CIOX services. Nothing in this Agreement shall
                        limit or exclude CIOX's liability for intentional misconduct of CIOX or its employees
                        in the performance of their duties hereunder. CIOX's aggregate liability and that of
                        its affiliates and suppliers under or in connection with this Agreement to one hundred
                        United States dollars ($100). This limitation will apply even if CIOX has been advised
                        of the possibility of such damages and notwithstanding any failure of essential
                        purpose of any limited remedy. USER further agrees that CIOX has no responsibility or
                        liability for the deletion, corruption, alteration of, or failure to store, any
                        documents, messages or other communications, or any other information, data, text
                        (including but not limited to names of files, databases, directories and
                        groups/workgroups of the same), software, music, sound, photographs, graphics and/or
                        video provided by USER (collectively referred to as "content") and created on,
                        uploaded to, or maintained or transmitted by CIOX or the Software. USER also agrees
                        that CIOX will not be liable for any (1) interruption of business, (2) access delays
                        or access interruptions to the Software; (3) unauthorized access to data entered in,
                        or breach of any security mechanisms utilized in, the Software; (4) any unauthorized
                        access to the stored data; or (5) any events beyond CIOX's reasonable control
                        including but not limited to USER's inability to access the Software due to issues
                        relating to USER's internet service, computer equipment or other computer software,
                        applications or programs (including web browsers) used to access the CIOX Software.
                        The limitations and exclusions in this section apply to the maximum extent permitted
                        by applicable law in your jurisdiction. Some jurisdictions prohibit the exclusion or
                        limitation of liability for incidental, consequential or punitive damages.
                        Accordingly, the limitations and exclusions set forth above may not apply to USER.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Indemnification. USER agrees to indemnify and defend CIOX, its parents, subsidiaries,
                        affiliates and/or their respective successors and assigns, officers, directors,
                        employees, agents, licensors, representatives, operational service providers, and
                        contractors (the "CIOX Indemnified Parties") against any and all claims, demands
                        and/or actions, losses, damages, costs and expenses (including reasonable attorneys'
                        fees), resulting from any claims, demands and/or actions related to the performance or
                        use of the services, website, and Software, , for USER's infringement, USER's
                        misappropriation of patient's information or any violation of the rights of any other
                        party, violation or non-compliance with any law or regulation by USER, failure by USER
                        to pay any fees to a healthcare provider and/or its business associate for the
                        duplication and delivery of a requested medical record, any use, alteration or export
                        of the Software by USER, or otherwise in connection with this Agreement or the
                        Software, regardless of whether caused in whole or in part by negligence of the CIOX
                        Indemnified Parties. The CIOX Indemnified Parties reserve the right to assume, at
                        their own expense, the exclusive defense and control of any claims or actions and all
                        negotiations for settlement or compromise, and USER agrees to fully cooperate with the
                        CIOX Indemnified Parties upon CIOX's request.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        TERMINATION. CIOX HAS THE RIGHT TO TERMINATE THIS LICENSE AND USER'S RIGHT TO USE THE
                        SOFTWARE AND SERVICES AT ANY TIME FOR ANY REASON, INCLUDING IN THE EVENT CIOX
                        REASONABLY BELIEVES USER HAS FAILED TO COMPLY WITH ANY TERM OR CONDITION OF THIS
                        AGREEMENT. IF THIS LICENSE OR USER'S RIGHT TO USE THE SOFTWARE AND SERVICES TERMINATES
                        FOR ANY REASON, USER MUST STOP ALL USE OF THE SOFTWARE AND SERVICES AND EITHER RETURN
                        TO CIOX OR DESTROY THE SOFTWARE AND ALL COPIES OF THE SOFTWARE THAT ARE IN USER'S
                        POSSESSION OR CONTROL. IN THE CASE OF SUCH TERMINATION, ALL AMOUNTS OWED TO CIOX AT
                        THE TIME OF TERMINATION, IN ADDITION TO ANY ADDITIONAL FEES AS SET FORTH HEREIN, WILL
                        IMMEDIATELY BECOME DUE AND PAYABLE TO CIOX ON DEMAND. CIOX MAY ASK USER TO CERTIFY
                        COMPLIANCE WITH THE FOREGOING IN WRITING, AND USER AGREES TO DO SO IF CIOX MAKES SUCH
                        A REQUEST.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        <UiKit.Chakra.UnorderedList
                            styleType={'none'}
                            spacing={UiKit.consistency.smallBuffer}
                        >
                            <UiKit.Chakra.ListItem>
                                Arbitration. Please read this provision carefully. It requires that any and
                                all claims must be resolved by binding arbitration or in small claims court.
                                These limitations apply to any claims against CIOX, its subsidiaries or
                                affiliates, or any travel service providers or companies offering products or
                                services through the site. Any dispute or claim relating in any way to your
                                use of any CIOX Service, or to any software or services licensed, sold or
                                distributed by CIOX or through CIOX website will be resolved by binding
                                arbitration, rather than in court, except that you may assert claims in small
                                claims court if your claims qualify. The Federal Arbitration Act and federal
                                arbitration law apply to this agreement.
                            </UiKit.Chakra.ListItem>
                            <UiKit.Chakra.ListItem>
                                There is no judge or jury in arbitration, and court review of an arbitration
                                award is limited. However, an arbitrator can award on an individual basis the
                                same damages and relief as a court (including injunctive and declaratory
                                relief or statutory damages) and must follow the terms of this Agreement as a
                                court would.
                            </UiKit.Chakra.ListItem>
                            <UiKit.Chakra.ListItem>
                                To begin an arbitration proceeding, you must send a letter requesting
                                arbitration and describing your claim to the CIOX Legal Department at 925
                                North Point Parkway, Suite 350, Alpharetta, GA 30005. The arbitration will be
                                conducted by the American Arbitration Association (AAA) under its rules,
                                including the AAA's Supplementary Procedures for Consumer-Related Disputes.
                                The AAA's rules are available at www.adr.org or by calling 1-800-778-7879.
                                Payment of all filing, administration and arbitrator fees will be governed by
                                the AAA's rules. You may choose to have the arbitration conducted by
                                telephone, based on written submissions, or in person in the county where you
                                live or at another mutually agreed location.
                            </UiKit.Chakra.ListItem>
                            <UiKit.Chakra.ListItem>
                                We each agree that any dispute resolution proceedings will be conducted only
                                on an individual basis and not in a class, consolidated or representative
                                action. If for any reason a claim proceeds in court rather than in arbitration
                                we each waive any right to a jury trial. We also both agree that you or we may
                                bring suit in court to enjoin infringement or other misuse of intellectual
                                property rights.
                            </UiKit.Chakra.ListItem>
                        </UiKit.Chakra.UnorderedList>
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Class Actions. USER agrees that it is prohibited from initiating or joining any class
                        action proceeding against CIOX and its affiliates, related to this offering of
                        software, products, or services through the CIOX website and under this AGREEMENT. Any
                        and all proceedings to resolve claims will be conducted only on an individual basis
                        and in accordance with arbitration provision contained herein. In addition, no
                        arbitration proceeding under this Agreement shall be consolidated or joined in any way
                        with any other arbitration proceeding without the express written consent of all the
                        parties.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Governing Law; Jurisdiction. By using any CIOX Service, you agree that the Federal
                        Arbitration Act, applicable federal law, and the laws of the state of Georgia, without
                        regard to principles of conflict of laws, will govern this Agreement and any dispute
                        of any sort that might arise between you and CIOX.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Survival of Agreement. All representations, warranties, limitations on liability,
                        exclusions of warranties, and any provisions governing the proprietary nature of the
                        Software, USER's duties upon termination of this Agreement, and the indemnification
                        and dispute resolution obligations of the parties, shall survive the termination of
                        this Agreement to the fullest extent allowed by law.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Pricing Structure. I hereby acknowledge that this Agreement is solely between CIOX
                        Health, LLC and USER for medical record retrieval, storage, and delivery. USER
                        acknowledges and agrees to pay the fees charged by CIOX in consideration for
                        retrieval, storage, and delivery of the requested medical records: per page copy fees,
                        duplication fees, reproduction fees, clerical labor, witness fees, research fees,
                        handling fees, base fees, shipping fees, electronic delivery fees, certification fees,
                        rush delivery fees, electronic data storage fees, no record found fees, applicable
                        taxes, and any other charges. Records will automatically delete from the server after
                        the purchased time expires. USER understands and agrees that the electronic data
                        storage fees charged in relation to your account with CIOX are fees agreed to in
                        contract for storing electronic medical records and are not fees regulated or covered
                        by any applicable state law, including but not limited to North Carolina General
                        Statutes §44-49.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        System Requirements. Operational on a Windows or Mac platform using the following
                        supported browsers with minimum version requirements: Chrome 91, Safari 14.1, Edge 91,
                        or Firefox 89.
                    </UiKit.Chakra.ListItem>
                    <UiKit.Chakra.ListItem>
                        Authority. By completing and submitting the registration for a CIOX Smart Request
                        account you affirm that you have the special and limited authority to establish a CIOX
                        HEALTH, LLC Smart Request account for your employer and its employees located at the
                        physical address provided on the request letter used to establish this account. CIOX
                        HEALTH, LLC will deliver all reproduced medical records requested by your employer and
                        its employees at the aforementioned address, through a secure internet portal
                        established and maintained by CIOX HEALTH, LLC, subject to the terms and conditions
                        contained herein.
                    </UiKit.Chakra.ListItem>
                </UiKit.Chakra.OrderedList>
            </UiKit.Text.BodyRegular>
        </UiKit.Chakra.VStack>
    </UiKit.Chakra.VStack>
);
