import React, { ReactFragment } from 'react';
import { UiKit } from '@datavant/idsb-shared/uikit';
import {
    disclosureNoticeTitle,
    privacyPolicyTitle,
    termsAndConditionsTitle,
    legalDisclaimerTitle,
} from './RecordHubPageContainer';
import {
    DisclosureNotice,
    PrivacyPolicy,
    TermsAndConditions,
    LegalDisclaimer,
} from './TOSPrivacyDisclosureText';

export const FooterModal = (props: {
    isOpen: any;
    onClose: any;
    title: string;
    isLoginModal: boolean;
}): JSX.Element => {
    let modalBody: JSX.Element;
    if (props.title === disclosureNoticeTitle) {
        modalBody = DisclosureNotice;
    } else if (props.title === termsAndConditionsTitle) {
        modalBody = TermsAndConditions;
    } else if (props.title === privacyPolicyTitle) {
        modalBody = PrivacyPolicy;
    } else if (props.title === legalDisclaimerTitle) {
        modalBody = LegalDisclaimer;
    } else {
        modalBody = <></>;
    }

    const modalFooter = (
        <UiKit.Chakra.HStack justifyContent={'flex-end'}>
            <UiKit.Button.Secondary
                text={'Close'}
                onClick={() => {
                    props.onClose();
                }}
            />
        </UiKit.Chakra.HStack>
    );

    return (
        <UiKit.Modal
            bodyFragment={modalBody as unknown as ReactFragment}
            footerFragment={modalFooter as unknown as ReactFragment}
            title={props.title}
            onClose={props.onClose}
            isOpen={props.isOpen}
            size={'4xl'}
            paddingTop={UiKit.consistency.largeBuffer}
            paddingBottom={UiKit.consistency.largeBuffer}
        />
    );
};
