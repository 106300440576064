import { extendTheme } from '@chakra-ui/react';
import { UiKit } from '@datavant/uikit';

export const DEFAULT_FONT = "'DM Sans', sans-serif";
export const PRIMARY_BLUE = '#006CCF';
export const PAGE_BACKGROUND = UiKit.consistency.white;

// @see https://chakra-ui.com/docs/theming/customize-theme
export const theme = extendTheme({
    colors: {
        // https://smart-swatch.netlify.app/#3cb3eb
        buttonPrimary: {
            50: '#dcf8ff',
            100: '#b3e5fd',
            200: '#86d2f5',
            300: '#5ac0ef',
            400: '#2faeea',
            500: '#3cb3eb',
            600: '#1594d0',
            700: '#0673a3',
            800: '#005276',
            900: '#003249',
        },
        // https://smart-swatch.netlify.app/#a0aec0
        buttonSecondary: {
            50: '#eaf3ff',
            100: '#eaf3ff',
            200: '#eaf3ff',
            300: '#ced8e4',
            400: '#b1becd',
            500: '#a0aec0',
            600: '#94a3b8',
            700: '#7689a2',
            800: '#5d7089',
            900: '#47576b',
        },
        bluePrimary: {
            500: PRIMARY_BLUE,
        },
        blueHover: {
            500: '#0064E6',
        },
        blueDisabled: {
            500: '#94C3FF',
        },
    },

    components: {
        Tabs: {
            baseStyle: {
                tabpanel: { p: 0 },
            },
        },
        Badge: {
            sizes: {
                md: {
                    height: '21px',
                    padding: '2px 7px',
                    marginRight: '10px',
                    borderRadius: '5px',
                },
                xs: {
                    hexight: '13px',
                    fontSize: '8px',
                    padding: '1px 4px',
                    marginRight: '4px',
                    borderRadius: '3px',
                },
            },
        },
    },

    fonts: {
        body: DEFAULT_FONT,
        heading: DEFAULT_FONT,
        mono: 'Menlo, monospace',
    },
});

export const colors = {
    background: PRIMARY_BLUE,
    panel: {
        background: '#eee',
        color: '#777',
        border: '#ccc',
    },
    pale: {
        highlight: 'aliceblue',
    },
    brand: {
        blue: PRIMARY_BLUE,
        cyan: '#4ab6a5',
        highlight: '#fecb18',
        neutral: '#34343c',
        primary: '#002452',
        purple: '#7b3c8c',
        red: '#ff0000',
    },
    table: {
        header: '#F7F9FA',
    },
};

export const SINGLE_BAR_CHART_COLOR = {
    BLUE: '#2FC0FF',
};

export const MIXED_BAR_CHART_COLOR = {
    BLUE: '#2FC0FF',
    PURPLE: '#AE5DEE',
    YELLOW: '#FFBE2E',
    INDIGO: '#5E5BFF',
    GREEN: '#4EE4C0',
    DARK_BLUE: '#006896',
    DARK_PURPLE: '#5D1099',
    DARK_YELLOW: '#86610E',
    DARK_INDIGO: '#222092',
    DARK_GREEN: '#0C785E',
};

export const PIE_CHART_COLORS = {
    BLUE: '#AEE7FF',
    DARK_BLUE: '#69D2FF',
    LIGHT_BLUE: '#94C3FF',
    DARK_GRAY: '#757575',
    DARK_PURPLE: '#2F019D',
    LIGHT_GRAY: '#C6CACE',
    PURPLE: '#9F7DE1',
    LIGHT_PURPLE: '#BDA8E6',
};
