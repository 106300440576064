import { UiKit } from '@datavant/uikit';
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { logout } from '../lib/authUtils';

export const SRLogoutButton = (): JSX.Element => {
    const { oktaAuth } = useOktaAuth();

    const onLogout = async (): Promise<void> => {
        // clear session storage on logout
        sessionStorage.clear();
        logout(oktaAuth);
    };

    return <UiKit.Button.Primary text="Logout" onClick={onLogout} w="100px" />;
};
