import React, { useEffect, useState } from 'react';
import { UiKit } from '@datavant/idsb-shared/uikit';
import { useOktaAuth } from '@okta/okta-react';
import jwtDecode from 'jwt-decode';
import { now } from 'moment';
import { logout } from '../lib/authUtils';

// This is when the modal countdown timer will start
// i.e. xx seconds before the token will expire, we'll show the modal and begin the countdown
const TOKEN_EXPIRE_COUNTDOWN_START = 15; // seconds

export const SessionExpiredModal = (): JSX.Element => {
    const { oktaAuth, authState } = useOktaAuth();
    const [showModal, setShowModal] = useState(false);
    // Countdown timer, used externally to display the countdown to the end user in the modal text
    const [secondsLeft, setSecondsLeft] = useState(undefined);

    useEffect(() => {
        const accessToken = authState?.accessToken?.accessToken;

        // If there is no access token, hide the modal and exit early
        if (!accessToken) {
            setShowModal(false);
            return;
        }

        // If we find an access token, set the seconds left until it expires to
        // the expiration time on the token.
        const exp = jwtDecode(accessToken)['exp']; // in epoch seconds
        const secondsUntilExp: number = Math.floor(exp - now() / 1000);
        setSecondsLeft(secondsUntilExp);

        // exit early when the countdown reaches 0
        if (secondsUntilExp <= 0) {
            logout(oktaAuth);
            return;
        }

        let timeoutId: ReturnType<typeof setTimeout>;
        // Show the modal if we're within the countdown start
        // Otherwise, hide the modal and then wait until the countdown starts to rerun this effect
        if (secondsUntilExp <= TOKEN_EXPIRE_COUNTDOWN_START) {
            setShowModal(true);
            // save timeout to clear the interval when the component re-renders
            timeoutId = setTimeout(() => {
                setSecondsLeft(secondsUntilExp - 1);
            }, 1000);
        } else {
            setShowModal(false);
            const msLeft = secondsUntilExp * 1000;
            const msCountdownStart = TOKEN_EXPIRE_COUNTDOWN_START * 1000;
            timeoutId = setTimeout(() => {
                setShowModal(true);
            }, msLeft - msCountdownStart);
        }

        // clear interval on re-render to avoid memory leaks
        return () => clearTimeout(timeoutId);

        // add timeLeft as a dependency to re-rerun the effect when we update it
    }, [secondsLeft, authState, showModal, oktaAuth]);

    // Modal component definitions

    const modalBody = (
        <UiKit.Chakra.VStack spacing={UiKit.consistency.buffer}>
            <UiKit.Hint.Warning colored={true}>
                <UiKit.Text.BodyRegular>
                    Your session is about to expire. You will be redirected to the login page in {secondsLeft}{' '}
                    seconds.
                </UiKit.Text.BodyRegular>
            </UiKit.Hint.Warning>
        </UiKit.Chakra.VStack>
    );

    const modalHeader = (
        <UiKit.Chakra.VStack align={'stretch'} p={UiKit.consistency.buffer}>
            <UiKit.Text.Heading7 text={'Session Expiring'} />
        </UiKit.Chakra.VStack>
    );

    const modalFooter = (
        <UiKit.Chakra.HStack align={'left'}>
            <UiKit.Button.Primary
                text={'Go to login'}
                onClick={() => {
                    logout(oktaAuth);
                }}
            />
        </UiKit.Chakra.HStack>
    );

    const onClose = (): void => {
        logout(oktaAuth);
    };

    return (
        <UiKit.Chakra.Modal
            autoFocus={false}
            onClose={onClose}
            isOpen={showModal}
            scrollBehavior={'outside'}
            isCentered={true}
            size={'xl'}
            closeOnOverlayClick={false}
        >
            <UiKit.Chakra.ModalOverlay />
            <UiKit.Chakra.ModalContent>
                <UiKit.Chakra.ModalHeader>{modalHeader}</UiKit.Chakra.ModalHeader>
                <UiKit.Chakra.ModalBody>{modalBody}</UiKit.Chakra.ModalBody>
                <UiKit.Chakra.ModalFooter>{modalFooter}</UiKit.Chakra.ModalFooter>
            </UiKit.Chakra.ModalContent>
        </UiKit.Chakra.Modal>
    );
};
