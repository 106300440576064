export const Text = {
    baseStyle: {
        color: 'gray.900',
    },
    variants: {
        regular: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
        },
        bold: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
        },
        smallRegular: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '18px',
        },
        smallBold: {
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '18px',
            color: 'gray.500',
        },
        label: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            color: 'gray.600',
        },
        placeholder: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            color: 'gray.400',
        },
        helper: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            color: 'gray.500',
        },
        error: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            color: 'error.400',
        },
        filterLabel: {
            display: 'block',
            width: '100vw',
            textTransform: 'uppercase',
            transform: 'rotate(90deg) translateX(0.35em)',
            transformOrigin: '100% 50%',
            position: 'absolute',
            top: '28px',
            right: '50%',
        },
    },
    defaultProps: {
        variant: 'regular',
    },
};
