import { UiKit } from '@datavant/uikit';
import { SRLogoutButton } from './SRLogoutButton';
import React, { useEffect, useState } from 'react';
import { TOP_NAV_HEIGHT } from '@datavant/idsb-shared/components/size';
import Image from 'next/image';
import logo from '../public/new-logo-white.png';
import cyanBar from '../public/cyan-bar.png';

import { CurrentSmartRequestUser, useUser } from '../lib/user';

const NewBrandTopNav = (props: { user: CurrentSmartRequestUser }): JSX.Element => {
    const userEmail = props?.user?.email ?? '';
    return (
        <UiKit.Chakra.HStack
            h={`${TOP_NAV_HEIGHT}px`}
            w={'100%'}
            position={'fixed'}
            top={0}
            left={0}
            right={0}
            p={`0 ${UiKit.consistency.buffer}`}
            zIndex={10000}
            background={UiKit.consistency.brandColors.sideNav.background}
            justifyContent={'space-between'}
        >
            <UiKit.Chakra.HStack spacing={'10px !important'}>
                <Image src={logo} alt="Datavant" width={'130'} height="24" />
                <Image src={cyanBar} alt="Cyan Bar" width={'9.64'} height={'27.87'} />
            </UiKit.Chakra.HStack>
            <UiKit.Text.Heading2 text={'Smart Request'} style={{ color: `${UiKit.consistency.white}` }} />
            <UiKit.Chakra.Spacer />
            <UiKit.Chakra.HStack spacing={UiKit.consistency.buffer}>
                <UiKit.Text.BodyRegular>
                    <span style={{ color: UiKit.consistency.white }}>{userEmail}</span>
                </UiKit.Text.BodyRegular>
                <SRLogoutButton />
            </UiKit.Chakra.HStack>
        </UiKit.Chakra.HStack>
    );
};

export const SRTopNav = (): JSX.Element => {
    const { user } = useUser();

    return <NewBrandTopNav user={user} />;
};
