export const Heading = {
    baseStyle: {
        margin: 0,
        color: 'gray.800',
    },
    sizes: {
        xl: {
            // Heading 1
            fontSize: '28px',
            fontWeight: '400',
            lineHeight: '40px',
            marginBottom: '14px',
        },
        l: {
            // Heading 2
            fontSize: '24px',
            fontWeight: '500',
            lineHeight: '30px',
        },
        md: {
            // Heading 3
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '22px',
        },
        s: {
            // Heading 4
            fontSize: '11px',
            fontWeight: '700',
            lineHeight: '12px',
            textTransform: 'uppercase',
            color: 'gray.500',
        },
        xs: {
            // Heading 5
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '20px',
            color: 'gray.600',
        },
    },
};
