import { extendTheme } from '@chakra-ui/react';
import { theme as defaultTheme } from '../default';

import {
    Breadcrumb,
    Button,
    Container,
    Divider,
    Drawer,
    FormLabel,
    Heading,
    Input,
    Modal,
    Switch,
    Tabs,
    Text,
    Textarea,
} from './components';
import { colors } from './colors';

// extending default theme to apply new portal styling to chakra components
// @see https://chakra-ui.com/docs/theming/customize-theme
export const theme = extendTheme(
    {
        colors,
        components: {
            Breadcrumb,
            Button,
            Container,
            Divider,
            Drawer,
            FormLabel,
            Heading,
            Input,
            Modal,
            Switch,
            Text,
            Textarea,
            Tabs,
        },
        styles: {
            global: {
                fontFamily: 'Lato, sans-serif;',
                body: {
                    fontFamily: 'Lato, sans-serif;',
                    bg: '#FAFAFA',
                },
            },
        },
    },
    defaultTheme
);
