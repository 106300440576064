import React, { createContext, useState } from 'react';
import { v4 as uuid } from 'uuid';

type RefreshCompaniesContextType = {
    refreshId: string;
    setRefreshId: (value: string) => void;
};

export const RefreshCompaniesContext = createContext<RefreshCompaniesContextType>({
    refreshId: '',
    setRefreshId: () => undefined,
});

export const RefreshCompaniesCacheProvider = (props: { children: React.ReactNode }): JSX.Element => {
    const [refreshId, setRefreshId] = useState(uuid());

    return (
        <RefreshCompaniesContext.Provider value={{ refreshId, setRefreshId }}>
            {props.children}
        </RefreshCompaniesContext.Provider>
    );
};
