export const Input = {
    parts: ['field'],
    baseStyle: {
        field: {
            backgroundColor: '#fff !important',
            fontSize: '48px',
        },
    },
    variants: {
        outline: {
            field: {
                borderColor: 'gray.200',
            },
        },
    },
};
