import React from 'react';
import { UiKit } from '@datavant/idsb-shared/uikit';
import { RecordHubPageContainer } from '../../components/SmartRequest/RecordHubPageContainer';

export const IS_SITE_SHUTDOWN_FOR_DB_MIGRATION = false; // to be set to true when the site is down for db migration

const ErpSystemMaintenancePage = (): JSX.Element => {
    return (
        <RecordHubPageContainer padding={'0'}>
            <UiKit.BoxWithHeader>
                <div style={{ textAlign: 'center' }}>
                    <h1
                        style={{
                            marginTop: '50px',
                            marginBottom: '50px',
                            fontSize: '25px',
                            fontWeight: 'bold',
                        }}
                    >
                        System Maintenance
                    </h1>
                    <p
                        style={{
                            marginBottom: '100px',
                            marginLeft: '10%',
                            marginRight: '10%',
                        }}
                    >
                        SmartRequest is undergoing <b>scheduled maintenance</b> and will be unavailable on{' '}
                        <b>Sunday 12/15</b> from approximately <b> 11AM</b> to <b>5PM</b> (ET).
                        <br />
                        We appreciate your patience.
                    </p>
                </div>
            </UiKit.BoxWithHeader>
        </RecordHubPageContainer>
    );
};

export default ErpSystemMaintenancePage;
