import React from 'react';
import { UiKit } from '@datavant/idsb-shared/uikit';
import { disclosureNoticeTitle, privacyPolicyTitle, termsAndConditionsTitle } from './RecordHubPageContainer';
import { DisclosureNotice, PrivacyPolicy, TermsAndConditions } from './TOSPrivacyDisclosureText';

export const LoginModal = (props: { isOpen: any; onClose: any; title: string }): JSX.Element => {
    const modalBody = (
        <UiKit.Chakra.VStack spacing={UiKit.consistency.buffer}>
            <UiKit.Hint.Info colored={true}>
                <UiKit.Text.BodyRegular>
                    Please read and accept the following to continue.
                </UiKit.Text.BodyRegular>
            </UiKit.Hint.Info>
            <UiKit.Text.Heading7 text={termsAndConditionsTitle} />
            {TermsAndConditions}
            <UiKit.Chakra.VStack marginTop={'100px !important'}>
                <UiKit.Text.Heading7 text={privacyPolicyTitle} />
                {PrivacyPolicy}
            </UiKit.Chakra.VStack>
            <UiKit.Chakra.VStack marginTop={'100px !important'}>
                <UiKit.Text.Heading7 text={disclosureNoticeTitle} />
                {DisclosureNotice}
            </UiKit.Chakra.VStack>
        </UiKit.Chakra.VStack>
    );

    const modalFooter = (
        <UiKit.Chakra.HStack align={'center'}>
            <UiKit.Button.Primary
                text={'Accept and Continue'}
                onClick={() => {
                    props.onClose(true);
                }}
            />
            <UiKit.Button.Secondary
                text={'Decline and Logout'}
                onClick={() => {
                    props.onClose(false);
                }}
            />
        </UiKit.Chakra.HStack>
    );

    // needed to recreate my own version of the terms of service modal so I could add in background blur
    const titleRow = props.title && (
        <UiKit.Chakra.HStack
            justify={'space-between'}
            align={'center'}
            width={'100%'}
            bgColor={UiKit.consistency.gray01}
            height={'250px'}
            paddingLeft={UiKit.consistency.buffer}
            paddingRight={UiKit.consistency.buffer}
            boxShadow={`inset 0px -1px 0px ${UiKit.consistency.gray05}`}
            borderTopRightRadius={UiKit.consistency.borderRadius}
            borderTopLeftRadius={UiKit.consistency.borderRadius}
        >
            <UiKit.Chakra.HStack justify="flex-start" align="center" spacing={2}>
                <UiKit.Text.Heading7 text={props.title} />
            </UiKit.Chakra.HStack>
        </UiKit.Chakra.HStack>
    );

    return (
        <UiKit.Chakra.Modal
            autoFocus={false}
            onClose={props.onClose}
            isOpen={props.isOpen}
            scrollBehavior={'outside'}
            isCentered={true}
            size={'6xl'}
            closeOnOverlayClick={false}
        >
            <UiKit.Chakra.ModalOverlay backgroundColor={UiKit.consistency.primaryBlue80} />
            <UiKit.Chakra.ModalContent
                borderRadius={UiKit.consistency.borderRadius}
                minH={'50px'}
                minW={'800px'}
            >
                {titleRow}
                <UiKit.Chakra.ModalBody p={0}>
                    <UiKit.Chakra.VStack align={'stretch'} p={UiKit.consistency.extraLargeBuffer}>
                        {modalBody}
                    </UiKit.Chakra.VStack>
                </UiKit.Chakra.ModalBody>
                <UiKit.Chakra.ModalFooter>{modalFooter}</UiKit.Chakra.ModalFooter>
            </UiKit.Chakra.ModalContent>
        </UiKit.Chakra.Modal>
    );
};
