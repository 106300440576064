export const Switch = {
    parts: ['track', 'thumb'],
    baseStyle: {
        track: {
            backgroundColor: 'gray.200',
            span: {
                backgroundColor: 'neutral.white',
            },

            _checked: {
                'backgroundColor': 'primary.500',

                '&:hover': {
                    backgroundColor: 'primary.400',
                },
            },

            _focus: {
                boxShadow: '0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #CBE3F1',
            },

            _disabled: {
                opacity: 1,
                backgroundColor: 'gray.20',

                span: {
                    backgroundColor: 'gray.100',
                },

                _checked: {
                    'backgroundColor': 'primary.200',
                    '&:hover': {
                        backgroundColor: 'primary.200',
                    },

                    'span': {
                        backgroundColor: 'neutral.white',
                    },
                },
            },
        },
        thumb: {
            backgroundColor: 'inherit',
        },
    },
    sizes: {
        md: {
            track: {
                width: '36px',
                height: '16px',
            },
            thumb: {
                width: '16px',
                height: '16px',

                _checked: {
                    transform: 'translateX(20px)',
                },
            },
        },
    },
};
