import React, { useEffect, useState } from 'react';
import { UiKit } from '@datavant/uikit';

const Button = (): JSX.Element => {
    const [isLiveChatOpen, setIsLiveChatOpen] = useState(false);

    return (
        <div
            style={{
                position: 'fixed',
                bottom: 40,
                left: 10,
                zIndex: 90000,
                display: 'block',
            }}
        >
            <UiKit.Button.TopBar
                text={isLiveChatOpen ? 'Close Chat' : 'Live Chat'}
                onClick={() => {
                    if (isLiveChatOpen) {
                        window['icPatronChat'].hide();
                    } else {
                        window['icPatronChat'].show();
                    }
                    setIsLiveChatOpen(!isLiveChatOpen);
                }}
                bgColor={UiKit.consistency.brandColors.primaryButton.background}
            />
        </div>
    );
};

export const RingCentral = {
    ScriptURL: 'https://home-c52.nice-incontact.com/inContact/ChatClient/js/embed.min.js',
    Button: Button,
    init: (): void => {
        window['icPatronChat'].init({
            serverHost: 'https://home-c52.nice-incontact.com',
            bus_no: 4603168,
            poc: '9ffd90ea-1a7c-40ce-b91e-8591cac852ec',
            params: ['FirstName', 'Last Name', 'first.last@company.com', 555 - 555 - 5555],
            customChatButton: true,
        });
        window['icPatronChat'].setDimension({
            top: 55,
            left: 10,
            height: 800,
            width: 600,
        });
    },
};
