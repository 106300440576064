import { colors } from '../colors';

// theme support for Breadcrumb is sadly quite limited:
// https://github.com/chakra-ui/chakra-ui/issues/2953
export const Breadcrumb = {
    parts: ['link'],
    baseStyle: {
        link: {
            color: colors.active,
        },
    },
};
