import { colors } from '../colors';

const BACKGROUND_COLOR = '0 0% 255%';
const SHADOW_COLOR = '0 0% 0%';

// available parts: "overlay", "dialogContainer", "dialog", "header", "closeButton", "body", "footer"
export const Modal = {
    parts: ['dialog', 'header', 'body', 'footer'],
    baseStyle: {
        dialog: {
            borderRadius: '4px',
            marginBottom: 0,
            marginTop: 0,
            maxHeight: '90vh',
            overflow: 'hidden',

            ['& > form']: {
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                overflow: 'hidden',
            },
        },
        header: {
            height: '54px',
            color: 'gray.800',
            backgroundColor: 'gray.10',
            padding: '8px 16px',
            borderBottom: `1px solid ${colors.gray[50]}`,
        },
        body: {
            background: `
        linear-gradient(hsl(${BACKGROUND_COLOR}) 33%, hsla(${BACKGROUND_COLOR} / 0)),
        linear-gradient(hsla(${BACKGROUND_COLOR} / 0), hsl(${BACKGROUND_COLOR}) 66%) 0 100%,
        radial-gradient(farthest-side at 50% 0, hsla(${SHADOW_COLOR} / 0.25), hsla(${SHADOW_COLOR} / 0)),
        radial-gradient(farthest-side at 50% 100%, hsla(${SHADOW_COLOR} / 0.25), hsla(${SHADOW_COLOR} / 0)) 0 100%`,
            backgroundAttachment: 'local, local, scroll, scroll',
            backgroundColor: `hsl(${BACKGROUND_COLOR})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 30px, 100% 30px, 100% 10px, 100% 10px',
            padding: '24px 16px',
            maxHeight: '80vh', // 'calc(100vh - 8rem)',
            overflow: 'auto',
        },
        footer: {
            padding: '16px',
            borderTop: `1px solid ${colors.gray[50]}`,
        },
    },
};
