import { PRIMARY_BLUE } from '../default';

export const colors = {
    active: '#0076D6',

    primary: {
        50: '#F0F6FF', // side menu item bg
        200: '#A1D3FF', // disabled blues
        400: '#0064E5', // link default state, hover states
        500: PRIMARY_BLUE, // default
        600: '#005EA2', // global nav hover
        800: '#112F4E', // top navbar bg color
    },

    gray: {
        10: '#FBFCFD',
        20: '#F7F9FA', // page bg
        30: '#F5F6F7',
        40: '#F1F3F6', // bg on hover for sidemenu item, table rows, flyout/context menus
        50: '#EDEFF0', // borders, horizontal lines (separating content, table rows)
        100: '#DFE1E2',
        200: '#C6CACE', // component: input fields
        300: '#A9AEB1',
        400: '#8D9297',
        500: '#71767A',
        600: '#626B79', // input label
        700: '#3D4551', // input label, unselected tab
        800: '#2D2E2F', // headings
        900: '#333231', // body text, input text
    },

    neutral: {
        white: '#FFFFFF',
        black: '#000000',
    },

    error: {
        50: '#FFF3F2',
        100: '#FDDEDC',
        200: '#FDB8AE',
        400: '#FB5A47',
        500: '#B50909', // error text
    },

    success: {
        50: '#F2FFFB',
        200: '#BDECDD',
        500: '#00A871',
    },

    warning: {
        50: '#FFFAED',
        200: '#FFE396',
        500: '#FFBE2E',
    },

    info: {
        50: '#F4F5FF',
        200: '#B8C8FF',
        500: '#617BFF',
    },

    accent: {
        50: '#F2F9FF',
        200: '#A1D3FF',
        500: '#2491FF',
    },
};

export const PIE_CHART_COLORS = {
    BLUE: '#AEE7FF',
    DARK_BLUE: '#69D2FF',
    LIGHT_BLUE: '#94C3FF',
    DARK_GRAY: '#757575',
    DARK_PURPLE: '#2F019D',
    LIGHT_GRAY: '#C6CACE',
    PURPLE: '#9F7DE1',
    LIGHT_PURPLE: '#BDA8E6',
};

export const SINGLE_BAR_CHART_COLOR = {
    BLUE: '#2FC0FF',
};

export const MIXED_BAR_CHART_COLOR = {
    BLUE: '#2FC0FF',
    PURPLE: '#AE5DEE',
    YELLOW: '#FFBE2E',
    INDIGO: '#5E5BFF',
    GREEN: '#4EE4C0',
    DARK_BLUE: '#006896',
    DARK_PURPLE: '#5D1099',
    DARK_YELLOW: '#86610E',
    DARK_INDIGO: '#222092',
    DARK_GREEN: '#0C785E',
};
