import { UiKit, UiKitIconType } from '@datavant/uikit';
import React, { ReactNode } from 'react';
import { PageTitle } from '@datavant/idsb-shared/components/PageTitle';
import { TOP_NAV_HEIGHT } from '@datavant/idsb-shared/components/size';
import { FormBreadcrumbsArray } from '@datavant/idsb-shared/formkit/types';
import { PAGE_BACKGROUND } from '../../themes/default';

export type PageContainerProps = {
    title?: string;
    subtitle?: ReactNode;
    titleButton?: {
        text: string;
        leftIcon?: UiKitIconType;
        onClick: () => void;
    };
    children: ReactNode;
    padding?: string;
    breadcrumbs?: FormBreadcrumbsArray;
    dontShowFooter?: boolean;
    height?: string;
};

export const PageContainer = (props: PageContainerProps): JSX.Element => {
    return (
        <UiKit.Chakra.VStack
            minW={'800px'}
            align={'stretch'}
            backgroundColor={PAGE_BACKGROUND}
            p={props.padding ?? UiKit.consistency.buffer}
            spacing={UiKit.consistency.buffer}
            height={props.height ?? `calc(100vh - ${TOP_NAV_HEIGHT}px)`}
        >
            {(props.title || props.titleButton) && (
                <UiKit.Chakra.HStack justify={'space-between'} align={'flex-start'}>
                    {props.title && <PageTitle title={props.title} breadcrumbs={props.breadcrumbs} />}
                    {props.titleButton && (
                        <UiKit.Button.Primary
                            text={props.titleButton.text}
                            leftIcon={props.titleButton.leftIcon}
                            onClick={props.titleButton.onClick}
                        />
                    )}
                </UiKit.Chakra.HStack>
            )}
            {props.subtitle}
            {props.children}
        </UiKit.Chakra.VStack>
    );
};
