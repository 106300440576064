import { ButtonProps } from '@chakra-ui/react';
import { DEFAULT_FONT } from '../../default';

const sizes = {
    xs: {
        fontSize: '12px',
        height: '20px',
        padding: '0 8px',
    },
    sm: {
        fontSize: '12px',
        height: '24px',
        padding: '0 12px',
    },
    md: {
        fontSize: '14px',
        height: '36px',
        padding: '0 16px',
    },
    lg: {
        fontSize: '16px',
        height: '40px',
        padding: '0 20px',
    },
};

export const Button = {
    baseStyle: {
        fontFamily: DEFAULT_FONT,
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
    },
    sizes,
    variants: {
        // primary
        solid: ({ colorScheme }: ButtonProps): any => ({
            backgroundColor: `${colorScheme ?? 'primary'}.500`,
            borderRadius: '4px',
            _hover: {
                backgroundColor: `${colorScheme ?? 'primary'}.400`,
            },
            _focus: {
                boxShadow: '0 0 0 2px #FFF, 0 0 0 4px #CBE3F1',
            },
        }),
        // secondary
        outline: ({ colorScheme }: ButtonProps): any => ({
            color: `${colorScheme ?? 'primary'}.500`,
            borderColor: `${colorScheme ?? 'primary'}.500`,
            backgroundColor: colorScheme === 'error' ? 'error.50' : 'neutral.white',
            borderRadius: '4px',
            _hover: {
                backgroundColor: `${colorScheme ?? 'primary'}.100`,
            },
            _focus: {
                boxShadow: '0 0 0 2px #FFF, 0 0 0 4px #CBE3F1',
            },
        }),
        // tertiary
        link: ({ colorScheme, size }: ButtonProps): any => ({
            backgroundColor: 'inherit',
            borderRadius: '3px',
            color: `${colorScheme ?? 'primary'}.500`,
            height: sizes[(size as string) ?? 'md']?.height,
            padding: sizes[(size as string) ?? 'md']?.padding,
            _focus: {
                boxShadow: '0 0 0 2px #CBE3F1',
            },
        }),
    },
};
