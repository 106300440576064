export const Textarea = {
    baseStyle: {
        borderRadius: '4px',
    },
    variants: {
        outline: {
            backgroundColor: 'neutral.white',
            borderColor: 'gray.200',
        },
    },
};
