import { DEFAULT_FONT } from '../../default';

export const Tabs = {
    parts: ['tab', 'tablist', 'tabpanel'],
    variants: {
        line: {
            tab: {
                'color': 'gray.700',
                'backgroundColor': 'inherit',
                'fontFamily': DEFAULT_FONT,
                'fontSize': '12px',
                'fontWeight': 700,
                'lineHeight': '16px',
                'textTransform': 'uppercase',
                'padding': '10px',
                'margin': '0 2px',

                '&:hover': {
                    backgroundColor: 'gray.40',
                    borderRadius: '4px',
                },

                '_selected': {
                    'color': 'primary.500',
                    'borderBottom': '2px solid',
                    'borderBottomColor': 'primary.500',
                    'padding': '10px 0px 10px 0px',
                    'margin': '0 12px',

                    '&:hover': {
                        color: 'primary.400',
                        borderBottomColor: 'primary.400',
                        backgroundColor: 'inherit',
                        borderRadius: '0',
                    },
                },

                '_active': {
                    backgroundColor: 'inherit',
                },
            },
            tablist: {
                paddingTop: '8px',
                borderBottom: 'none !important',
            },
            tabpanel: {
                padding: '16px 0',
            },
        },
    },
};
