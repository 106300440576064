import { PAGE_BACKGROUND } from '../../default';

export const Container = {
    baseStyle: {
        maxWidth: 'inherit',
    },
    variants: {
        page: {
            backgroundColor: PAGE_BACKGROUND,
            padding: '32px 32px 80px 32px',
            margin: '0',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0',
        },
        panel: {
            backgroundColor: 'white',
            borderRadius: '4px',
            padding: '20px',
            margin: '0',
            border: '1px solid',
            borderColor: 'gray.50',
        },
        leftPanel: {
            borderRight: '2px solid',
            borderRightColor: 'gray.50',
            padding: '0 16px 0 0',
            margin: '0',
            width: '340px',
            flexShrink: 0,
            height: '100%',
        },
        rightPanel: {
            padding: '0 0 0 32px',
            margin: '0',
            width: '100%',
        },
        infoPanel: {
            backgroundColor: 'white',
            borderRadius: '4px',
            boxSizing: 'border-box',
            border: '1px solid',
            borderColor: 'gray.50',
            marginRight: 0,
            marginLeft: 0,
            marginTop: 0,
            marginBottom: '24px',
            padding: '16px 20px 16px 20px',
        },
        infoPanelHeader: {
            margin: '-16px -20px 8px -20px',
            padding: '16px 20px 8px 20px',
            width: 'calc(100% + 40px)',
        },
        infoPanelHeaderCollapsed: {
            margin: '0',
            padding: '0 0 0 0',
            width: '100%',
        },
        footer: {
            position: 'fixed',
            right: '0',
            bottom: '0',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            backgroundColor: 'white',
            width: 'calc(100% - 220px)',
            height: '70px',
            margin: '0',
            padding: '0',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
        },
        toast: {
            backgroundColor: 'white',
            borderRadius: '4px',
            padding: '18px',
            margin: '0',
            border: '1px solid',
            borderColor: 'gray.50',
            boxShadow: '0px 8px 12px rgba(17, 47, 78, 0.08), 0px 0px 1px rgba(17, 47, 78, 0.24)',
            width: '360px',
        },
        dialogContent: {
            // transparency required to support vertical scroll bg shadow on modals and drawers
            backgroundColor: 'rgba(221, 231, 232, 0.2)',
            padding: '16px',
            margin: '0',
        },
        filter: {
            position: 'fixed',
            right: '0px',
            top: '48px',
            margin: '0',
            padding: '32px 16px',
            width: '52px',
            height: 'calc(100vh - 48px)',
            backgroundColor: 'neutral.white',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
        },
    },
};
