import { postData } from '@datavant/idsb-shared/utils';
import { getEnv } from '@datavant/idsb-shared/utils/browserEnvironmentVariables';
import { OktaAuth } from '@okta/okta-auth-js';

export const logout = (oktaAuth: OktaAuth): void => {
    const env = getEnv();
    postData('/api/open/logout', {}).catch(error => {
        console.error(error);
    });
    oktaAuth
        .signOut({
            postLogoutRedirectUri:
                env === 'dev' ? window.location.origin : window.location.origin + '/logout',
        })
        .catch(error => {
            // TODO: Do something better
            console.error(error);
        });
};
