export const Divider = {
    variants: {
        infoPanel: {
            height: '1px',
            padding: '0',
            margin: '8px 0 16px 0',
            backgroundColor: 'gray.50',
            width: '100%',
        },
    },
    defaultProps: {
        variant: 'infoPanel',
    },
};
